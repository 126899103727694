<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot'
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #F2C98A;"
      >
        <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
          :style="{ backgroundImage: `url(${backgroundImage})`, backgroundPositionY: 'center'}"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signup-->
          <div class="login-form login-signup">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signup_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  サインアップ
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">アカウントを作成するには、詳細を入力してください
                </p>
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="text"
                  placeholder="Fullname"
                  name="fullname"
                  ref="rfullname"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  placeholder="Email"
                  name="email"
                  ref="remail"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="text"
                  placeholder="User name"
                  name="user_name"
                  ref="rusername"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  placeholder="Password"
                  name="password"
                  ref="rpassword"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  placeholder="Confirm password"
                  name="cpassword"
                  ref="rcpassword"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="number"
                  placeholder="Phone"
                  name="phone"
                  ref="rphone"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="text"
                  placeholder="Website"
                  name="website"
                  ref="rwebsite"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="text"
                  placeholder="Company name"
                  name="company_name"
                  ref="rcompanyname"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <label class="checkbox mb-0">
                  <input type="checkbox" name="agree" />
                  <span class="mr-2"></span>
                  同意します
                  <a href="#" class="ml-2">規約と条件</a>.
                </label>
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                <button
                  ref="kt_login_signup_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                  style="width:150px;"
                >
                  送信
                </button>
                <button
                  type="button"
                  id="kt_login_signup_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="goToLogin()"
                >
                  取消
                </button>
              </div>
            </form>
          </div>
          <!--end::Signup-->
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT, REGISTER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

export default {
  name: "login-1",
  data() {
    return {
      state: "signup",
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser", "getError"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/logos/rogo.png"
      );
    }
  },
  mounted() {
    const signup_form = KTUtil.getById("kt_login_signup_form");

    this.fv1 = formValidation(signup_form, {
      fields: {
        fullname: {
          validators: {
            notEmpty: {
              message: this.$t('required'),
            }
          }
        },
        email: {
          validators: {
            notEmpty: {
              message: this.$t('required'),
            },
            emailAddress: {
              message: this.$t('validateEmail'),
            },
          }
        },
        user_name: {
          validators: {
            notEmpty: {
              message: this.$t('required'),
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: this.$t('required'),
            }
          }
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: this.$t('required'),
            },
            identical: {
              compare: function() {
                return signup_form.querySelector('[name="password"]').value;
              },
              message: this.$t('confirmPassword'),
            }
          }
        },
        phone: {
          validators: {
            notEmpty: {
              message: this.$t('required'),
            },
            minlength:10,
            maxlength:10
          }
        },
        website: {
          validators: {
            notEmpty: {
              message: this.$t('required'),
            }
          }
        },
        company_name: {
          validators: {
            notEmpty: {
              message: this.$t('required'),
            }
          }
        },
        agree: {
          validators: {
            notEmpty: {
              message: this.$t('agree'),
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv1.on("core.form.valid", () => {
      const fullname = this.$refs.rfullname.value;
      const email = this.$refs.remail.value;
      const password = this.$refs.rpassword.value;
      const cpassword = this.$refs.rcpassword.value;
      const username = this.$refs.rusername.value;
      const phone = this.$refs.rphone.value;
      const website = this.$refs.rwebsite.value;
      const companyname = this.$refs.rcompanyname.value;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signup_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send register request
        this.$store
          .dispatch(REGISTER, {
            name: fullname,
            email: email,
            password: password,
            confirm_password: cpassword,
            user_name: username,
            phone: phone,
            website: website,
            company_name: companyname
          })
          .then(() => {
            this.$router.push({ name: "app" })
            this.notifySuccess(this.$t('register_success'));
          })
          .catch((response) => {
            this.getError.forEach((value, key) => {
              setTimeout(() => {
                this.notifyError(value);
              }, 500)
            });
          });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    });
  },

  methods: {

    goToLogin() {
      this.$router.push({ name: "login" });
    }
  }
};
</script>
